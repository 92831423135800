/**
 * 处理管理中心,子路由
 */
import * as PerformanceManagement from "../modules/PerformanceManagement";
import { addSubRoutes } from "@/router/helper";
import * as RuleChildren from "../modules/RuleManagement";
import * as DataChildren from "../modules/DataManagement";
import * as UpgradeChildren from "../modules/UpgradeManagement";
import * as claimManagement from "../modules/claimManagement";
import * as PartsRetrieval from "../modules/PartsRetrieval";
import * as ProjectManagement from "../modules/ProjectManagement";
import * as ProjectManagementChidren from "../modules/ProjectManagementChidren";
import * as claimChildren from "../modules/claimChildren";
import * as policyChidren from "../modules/policyChidren";
import * as policyCarInsuranceChidren from "../modules/policyCarInsuranceChidren";
import * as policyInsuranceChidren from "../modules/policyInsuranceChidren";
import * as policyManagement from "../modules/policyManagement";
import * as calculateManagement from "../modules/calculateManagement";
import * as policyAuditChidren from "../modules/policyAuditChidren";
import * as InsuredScheme from "../modules/InsuredScheme";
import * as Distribution from "../modules/Distribution";
import * as safeDutyInsured from "../modules/safeDutyInsuredManagement";
import * as safeDutyBoardchildren from "../modules/sdDataBoardchildren";
import * as safeDutyInsuredchildren from "../modules/safeDutyInsuredchildren";
import * as safeDutyServicechildren from "../modules/safeDutyServicechildren";
import * as safeDutyTrafficChildren from "../modules/safeDutyTrafficChildren";
import * as newEnergyClaim from "../modules/newEnergyClaimManagement";

/**
 *
 * @param {}} ManagementCenter 管理中心路由
 */
export default function handle(ManagementCenter) {
  // 给规则管理添加子路由
  addSubRoutes(ManagementCenter.RuleManagement, Object.values(RuleChildren));
  // 给资料管理添加子路由
  addSubRoutes(ManagementCenter.DataManagement, Object.values(DataChildren));

  addSubRoutes(
    ManagementCenter.UpgradeManagement,
    Object.values(UpgradeChildren)
  );

  addSubRoutes(claimManagement.auditManagement, Object.values(claimChildren));

  addSubRoutes(
    ManagementCenter.claimManagement,
    Object.values(claimManagement)
  );
  addSubRoutes(
    claimManagement.newEnergyClaimManagement,
    Object.values(newEnergyClaim)
  );

  addSubRoutes(ManagementCenter.PartsManagement, Object.values(PartsRetrieval));

  addSubRoutes(
    ProjectManagement.traditionalProject,
    Object.values(ProjectManagementChidren)
  );

  addSubRoutes(
    ManagementCenter.ProjectManagement,
    Object.values(ProjectManagement)
  );

  // addSubRoutes(
  //   ManagementCenter.PerformanceManagement,
  //   Object.values(PerformanceManagement)
  // );

  addSubRoutes(
    policyManagement.CarInsurance,
    Object.values(policyCarInsuranceChidren)
  );

  addSubRoutes(
    policyManagement.Insurance,
    Object.values(policyInsuranceChidren)
  );

  addSubRoutes(
    policyManagement.ConstructionMachinery,
    Object.values(policyChidren)
  );

  addSubRoutes(policyManagement.policyAudit, Object.values(policyAuditChidren));

  addSubRoutes(
    policyManagement.insuredManagement,
    Object.values(InsuredScheme)
  );

  addSubRoutes(
    ManagementCenter.policyManagement,
    Object.values(policyManagement)
  );

  addSubRoutes(
    ManagementCenter.calculateManagement,
    Object.values(calculateManagement)
  );

  addSubRoutes(ManagementCenter.Distribution, Object.values(Distribution));

  addSubRoutes(
    safeDutyInsured.safeDutyDataBoardIndex,
    Object.values(safeDutyBoardchildren)
  );
  addSubRoutes(
    safeDutyInsured.safeDutyManagementIndex,
    Object.values(safeDutyInsuredchildren)
  );
  addSubRoutes(
    safeDutyInsured.safeDutyServiceManagementIndex,
    Object.values(safeDutyServicechildren)
  );
  addSubRoutes(
    safeDutyInsured.safeDutyTrafficIndex,
    Object.values(safeDutyTrafficChildren)
  );
  addSubRoutes(
    ManagementCenter.safeDutyInsuredManagement,
    Object.values(safeDutyInsured)
  );
}
