/**
 * 保单
 */

//工程机械
export const ConstructionMachinery = {
  path: "/ConstructionMachinery",
  name: "ConstructionMachinery",
  component: () => import("@/views/policyManagement/ConstructionMachinery"),
  meta: {
    label: "工程机械险管理",
    permId: "M20-View_1",
    icon: require("@/assets/images/policyInquiry.png"),
    redirect: true,
  },
};

//车险
export const CarInsurance = {
  path: "/CarInsurance",
  name: "CarInsurance",
  component: () => import("@/views/policyManagement/CarInsurance"),
  meta: {
    label: "车险管理",
    permId: "M20-View_2",
    icon: require("@/assets/images/policyInquiry.png"),
    redirect: true,
  },
};

//非设备类财产险
export const Insurance = {
  path: "/Insurance",
  name: "Insurance",
  component: () => import("@/views/policyManagement/Insurance"),
  meta: {
    label: "非设备类财产险管理",
    permId: "M20-View_3",
    icon: require("@/assets/images/policyInquiry.png"),
    redirect: true,
  },
};

//保单审核
export const policyAudit = {
  path: "/policyAudit",
  name: "policyAudit",
  component: () => import("@/views/policyManagement/policyAudit"),
  meta: {
    label: "保单审核",
    permId: "M20-RepeatAudit",
    icon: require("@/assets/images/policyInquiry.png"),
    affix: true,
    redirect: true,
  },
};

// //询价单管理
// export const enquiryManagement = {
//    path: '/enquiryManagement',
//    name: 'enquiryManagement',
//    component: () => import('@/views/EnquiryManagement'),
//    meta: {
//       label: '询价单管理',
//       permId: 'M26',
//       icon: require('@/assets/images/policyInquiry.png'),
//       affix: true,
//    }
// };
// /**
//  * 保单管理
//  */
// export const policyManagement = {
//    path: '/policyManagement',
//    name: 'policyManagement',
//    component: () => import('@/views/policyManagement'),
//    meta: {
//       label: '保单管理',
//       permId: 'M20',
//       icon: require('@/assets/images/policyManagement.png'),
//       affix: true,
//    },
// };
// //承保方案管理
// export const insuredManagement = {
//    path: '/insuredManagement',
//    name: 'insuredManagement',
//    component: () => import('@/views/InsuredManagement'),
//    meta: {
//       label: '承保方案管理',
//       permId: 'M23',
//       icon: require('@/assets/images/policyScheme.png'),
//       affix: true,
//    }
// };

// //批单创建
// export const createCorrectionOrder = {
//    path: '/createCorrectionOrder/:orderId?',
//    name: 'createCorrectionOrder',
//    component: () => import('@/views/CorrectionSheetManagement/pages/CreateCorrection'),
//    meta: {
//       label: '批单创建',
//       permId: 'M25',
//       icon: 'icon-anquanguanli2',
//       hideMenu: true
//    }
// };

// //批单详情
// export const correctionDetails = {
//    path: '/correctionDetails/:riderId',
//    name: 'correctionDetails',
//    //props: true,
//    component: () => import('@/views/CorrectionSheetManagement/pages/CorrectionDetails'),
//    meta: {
//       label: '批单详情',
//       permId: 'M25',
//       icon: 'icon-anquanguanli2',
//       hideMenu: true
//    }
// };

// //批单修改
// export const correctionEdit = {
//    path: '/correctionEdit/:approvalType/:orderId?/:riderId?',
//    name: 'correctionEdit',
//    component: () => import('@/views/CorrectionSheetManagement/pages/CorrectionEdit'),
//    meta: {
//       label: '批单修改',
//       permId: 'M25',
//       icon: 'icon-anquanguanli2',
//       hideMenu: true
//    }
// };

// //批单管理
// export const correctionSheetManagement = {
//    path: '/correctionSheetManagement',
//    name: 'correctionSheetManagement',
//    component: () => import('@/views/CorrectionSheetManagement'),
//    meta: {
//       label: '批单管理',
//       permId: 'M25',
//       icon: require('@/assets/images/policyCorrection.png'),
//       affix: true,
//    }

// };

// //续保管理
// export const renewalManagement = {
//    path: '/renewalManagement',
//    name: 'renewalManagement',
//    component: () => import('@/views/RenewalManagement'),
//    meta: {
//       label: '续保管理',
//       permId: 'M24',
//       icon: require('@/assets/images/policyRenewal.png'),
//       affix: true,
//    }
// };

// export const enquiryEdit = {
//    path: '/enquiryEdit/:id?/:type?',
//    name: 'enquiryEdit',
//    component: () => import('@/views/EnquiryManagement/enquiryEdit.vue'),
//    meta: {
//       label: '询价单编辑',
//       permId: 'M26',
//       icon: 'icon-anquanguanli2',
//       hideMenu: true
//    }
// };

export const policyHosting = {
  path: "/policyHosting",
  name: "policyHosting",
  component: () => import("@/views/policyManagement/policyHosting/index.vue"),
  meta: {
    label: "保单托管",
    permId: "M26",
    icon: "icon-anquanguanli2",
  },
};

export const hostingEdit = {
  path: "/hostingEdit",
  name: "hostingEdit",
  component: () =>
    import("@/views/policyManagement/policyHosting/hostingEdit.vue"),
  meta: {
    label: "添加托管单",
    permId: "M26",
    icon: "icon-anquanguanli2",
    hideMenu: true,
  },
};

export const hostingDetails = {
  path: "/hostingDetails",
  name: "hostingDetails",
  component: () =>
    import("@/views/policyManagement/policyHosting/hostingDetails.vue"),
  meta: {
    label: "托管单详情",
    permId: "M26",
    icon: "icon-anquanguanli2",
    hideMenu: true,
  },
};

// export const enquiryAdd = {
//    path: '/enquiryAdd',
//    name: 'enquiryAdd',
//    component: () => import('@/views/EnquiryManagement/enquiryAdd.vue'),
//    meta: {
//       label: '询价单添加',
//       permId: 'M26',
//       icon: 'icon-anquanguanli2',
//       hideMenu: true
//    }
// };

// export const enquiryDetails = {
//    path: '/enquiryDetails/:id?',
//    name: 'enquiryDetails',
//    component: () => import('@/views/EnquiryManagement/enquiryDetails.vue'),
//    meta: {
//       label: '询价单详情',
//       permId: 'M26',
//       icon: 'icon-anquanguanli2',
//       hideMenu: true
//    }
// };

// export const policyDetails = {
//    path: '/policyDetails/:id',
//    name: 'policyDetails',
//    component: () => import('@/views/policyManagement/policyDetails.vue'),
//    meta: {
//       label: '保单详情',
//       permId: 'M20',
//       icon: 'icon-anquanguanli2',
//       hideMenu: true
//    }
// };

//承保方案管理
export const insuredManagement = {
  path: "/insuredManagement",
  name: "insuredManagement",
  component: () => import("@/views/policyManagement/InsuredManagement"),
  meta: {
    label: "承保方案管理",
    permId: "M20-scheme",
    icon: require("@/assets/images/policyScheme.png"),
    redirect: true,
  },
};

export const insureConfirmRecord = {
  path: "/insureConfirmRecord",
  name: "insureConfirmRecord",
  component: () =>
    import("@/views/policyManagement/insureConfirmRecord/index.vue"),
  meta: {
    label: "投保确认记录",
    permId: "M29",
    icon: "icon-anquanguanli2",
  },
};

// // 安全责任保险管理
// export const safeDutyInsuredManagement = {
//   path: "/safeDutyInsuredManagement",
//   name: "safeDutyInsuredManagement",
//   component: () => import("@/views/safeDutyInsuredManagement/index.vue"),
//   meta: {
//     label: "安责险保单管理",
//     permId: "M20-sd",
//     icon: "icon-anquanguanli2",
//     redirect: true,
//   },
// };

/**
 * 保单项目
 */
export const SubProjectManagementList = {
  path: "/SubProjectManagementList",
  name: "SubProjectManagementList",
  component: () =>
    import("@/views/routerProjectManagement/components/SubProjectList"),
  meta: {
    label: "保单项目",
    name: "SubProjectList",
    permId: "project-sub-list",
    // FXJLJK MTJGL
    icon: require("@/assets/images/header/零配件检索.png"),
  },
};
