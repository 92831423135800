// 新能源理赔
export const newEnergyClaimList = {
  path: "/newEnergyClaimList",
  name: "newEnergyClaimList",
  component: () => import("@/views/newEnergyClaim/claim/index.vue"),
  meta: {
    label: "理赔列表",
    permId: "M21NewEng-list",
    icon: require("@/assets/images/safeDuty/menu/企业管理.png"),
  },
};

//新能源资讯
export const newEnergyNewsInformation = {
  path: "/newEnergyNewsInformation",
  name: "newEnergyNewsInformation",
  component: () => import("@/views/newEnergyClaim/message/index.vue"),
  meta: {
    label: "新能源资讯",
    permId: "M21NewEng-new",
    icon: require("@/assets/images/safeDuty/menu/企业管理.png"),
  },
};

// 理赔详情
export const newEnergyClaimDetails = {
  path: "/newEnergyClaimDetails",
  name: "newEnergyClaimDetails",
  component: () => import("@/views/newEnergyClaim/claim/neClaimDetails.vue"),
  meta: {
    label: "理赔详情",
    hideMenu: true,
    permId: "M21NewEng-details",
    icon: require("@/assets/images/safeDuty/menu/企业管理.png"),
  },
};

// 附件详情
export const newEnergyClaimAttachmentDetails = {
  path: "/newEnergyClaimAttachmentDetails",
  name: "newEnergyClaimAttachmentDetails",
  component: () => import("@/views/newEnergyClaim/claim/attachments.vue"),
  meta: {
    label: "附件详情",
    hideMenu: true,
    permId: "M21NewEng-attachment",
    icon: require("@/assets/images/safeDuty/menu/企业管理.png"),
  },
};
