<template>
  <span :style="wrapperStyle" :title="title" :name="name" v-if="name && name.indexOf('http') == -1 && name.indexOf('.png') == -1&& name.indexOf('data:image') == -1">
    <svg :style="iconStyle" :class="['icon']" aria-hidden="true"  @click="$emit('click',$event)">
      <use :xlink:href="iconName" />
    </svg>
  </span>
  <span v-else-if="name">
    <img :src="name" :style="{height: height ? height + 'px' : size + 'px', width: size + 'px', marginTop: mTop + 'px'}" alt />
  </span>
  <div v-else :style="{height: height ? height + 'px' : size + 'px', width: size + 'px', marginTop: mTop + 'px'}"></div>
</template>

<script>

// NOTE: svg可以通过`font-size`改变图标大小
// NOTE: svg通过`color`改变图标颜色, 需要在iconfont.cn上拷贝一个副本, 并去色, 建议副本命名: 图标名称-0
// 去色方法: iconfont.cn -> [批量操作] -> [选择图标] -> [批量去色] -> [确认]


export default {
  name: 'Iconfont',
  props: {
    /**
     * 悬停提示
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * iconfont图标名称
     */
    name: {
      type: String,
      default: 'icon-a-zu517'
    },
    /**
     * 大小
     */
    size: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    /**
     * 颜色, 需要图标去色才能生效
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * 图标外边距
     */
    margin: {
      type: String,
      default: null
    },
    // 旋转角度
    rotate: {
      type: Number,
      default: 0,
    },
    /**
     * margin-top
     */
    mTop: {
      type: Number,
      default: null,
    },
    /**
     * margin-left
     */
    mLeft: {
      type: Number,
      default: null,
    },
    /**
     * margin-right
     */
    mRight: {
      type: Number,
      default: null,
    },
    /**
     * margin-bottom
     */
    mBottom: {
      type: Number,
      default: null,
    },
    /**
     * 鼠标样式
     */
    cursor: {
      type: String,
      default: 'pointer',
    },
  },
  computed: {
    iconName() {
      const { name } = this;
      return `#${ name }`;
    },
    wrapperStyle() {
      const { margin, mTop, mLeft, mRight, mBottom, cursor, size } = this;
      const styleObj = {};
      margin && (styleObj.margin = margin);
      mTop && (styleObj.marginTop = `${ mTop }px`);
      mLeft && (styleObj.marginLeft = `${ mLeft }px`);
      mRight && (styleObj.marginRight = `${ mRight }px`);
      mBottom && (styleObj.marginBottom = `${ mBottom }px`);
      size && (styleObj.fontSize = `${ size }px`);
      cursor && (styleObj.cursor = cursor);
      return styleObj;
    },
    iconStyle() {
      const { size, color, rotate } = this;

      const styleObj = {
        transform: `rotate(${ rotate }deg)`,
      };

      size && (styleObj.fontSize = `${ size }px`);
      color && (styleObj.color = color);

      return styleObj;
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  cursor: inherit;
}
img {
  width: 1em;
  height: 1em;
  margin-right: 6px;
  margin-top: 4px;
  cursor: pointer;
}
</style>