/**
* 项目列表页
*/
export const regulationManagementList = {
  path: '/regulationManagementList',
  name: 'regulationManagementList',
  component: () => import('@/views/routerPerformanceManagement/components/regulationManagementList'),
  meta: {
     label: '规则管理',
     name: 'regulationManagementList',
     permId: 'performance-regulation',
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};

export const staffManagementList = {
  path: '/staffManagementList',
  name: 'staffManagementList',
  component: () => import('@/views/routerPerformanceManagement/components/staffManagementList'),
  meta: {
     label: '员工管理',
     name: 'staffManagementList',
     permId: 'performance-staff',
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};

export const staffDetails = {
  path: '/staffDetails',
  name: 'staffDetails',
  component: () => import('@/views/routerPerformanceManagement/components/staffManagementList/staffDetails'),
  meta: {
     label: '员工详情',
     name: 'staffDetails',
     permId: 'performance-staffDetails',
     hideMenu: true,
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};

export const generalManagementList = {
  path: '/generalManagementList',
  name: 'generalManagementList',
  component: () => import('@/views/routerPerformanceManagement/components/generalManagementList'),
  meta: {
     label: '绩效总表',
     name: 'generalManagementList',
     permId: 'performance-general',
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};

export const performancePanel = {
  path: '/performancePanel',
  name: 'performancePanel',
  component: () => import('@/views/routerPerformanceManagement/components/performancePanel'),
  meta: {
     label: '数据看板',
     name: 'performancePanel',
     permId: 'performance-panel',
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};